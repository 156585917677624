
import { required, email } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { mapGetters } from 'vuex';

export default {
  name: 'Subscription',
  mixins: [validationMixin],
  data: () => ({
    firstName: '',
    emailAddress: '',
    submitted: false,
  }),
  validations: {
    firstName: { required },
    emailAddress: { required, email },
  },
  computed: {
    ...mapGetters({
      ipAddress: 'session/getIpAddress',
    }),
    lang () {
      return this.$i18n.locale || 'en';
    },
  },
  methods: {
    handleSubmit () {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$nextTick(() => {
          const firstErrorField = document.querySelector('.form--invalid');
          firstErrorField &&
            firstErrorField.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
        });
        // eslint-disable-next-line no-useless-return
        return;
      }
      this.subscribe({
        firstName: this.firstName,
        emailAddress: this.emailAddress,
      });
    },
    async subscribe (data) {
      if (this.lang === 'en') {
        await this.$axios.$post(
          `https://api.vbout.com/1/emailmarketing/addcontact?key=6865530531874006703622008&listid=73946&status=active&email=${data.emailAddress}&fields[492309]=${data.firstName}&ipaddress=${this.ipAddress}`
        );
      } else if (this.lang === 'fr') {
        await this.$axios.$post(
          `https://api.vbout.com/1/emailmarketing/addcontact?key=6865530531874006703622008&listid=73945&status=active&email=${data.emailAddress}&fields[492309]=${data.firstName}&ipaddress=${this.ipAddress}`
        );
      }
      this.firstName = '';
      this.emailAddress = '';
      this.submitted = false;
      this.$router.push(this.localeLocation('/success'));
    },
  },
};
